import React, { Component } from "react";
import isEmpty from "lodash/isEmpty";
// import Image from "../Image";
import PropTypes from "prop-types";
import { TopRecognitionContainerV2, RecognitionReceivedContainerV2, RecognitionReceivedHeaderV2, RecognisedUserV2, UserProfileContainerV2, UserNameContainerV2, NameV2, RecognitionFrequencyV2, CardContainerr, YearDropDownV2 } from "./styles";
// import { MenuItem } from "react-bootstrap";
// import { ImageUrl } from '../../utils/constants';
import CSVList from "../AttendeesModal/CSVList";
import moment from "moment";
import { BuddyTab, BuddyTabContainer } from "../PeopleHomeV2/styles";
import { CaptainLogo, CustomTitle, MemberDataV3, SeeAllStyledModal, TeamCardNew, ToolTip } from "../AdminTeamCreation/styles";
import { DropdownItem, Modal, OverlayTrigger, Popover } from "react-bootstrap";
import { connect } from 'react-redux';
import LazyImage from "../common/LazyImage/LazyImage";
import { ImageUrl } from "../../utils/constants";
import { WellnessChampion } from "../../utils/icons";
// import FileSaver from "file-saver";
// import { Blob } from 'blob-polyfill';

class Anniversaries extends Component {
  constructor() {
    super();
    this.state = {
      userId: null,
      initiativeId: null,
      initiativeName: null,
      locationDropDownValue: {location:"All Locations",id:null},
      departmentDropDownValue: {department:"All Departments",id:null},
      printYearEmployeeCSV: false,
      updatedYearEmployeeCSV: [],
      printMonthEmployeeCSV: false,
      updatedMonthEmployeeCSV: [],
      fileTitleMonth: "EmployeeOfMonth",
      fileTitleQuarter: "EmployeeOfQuarter",
      showTeams: true,
      teamButtonActive:false,
      SeeAll:false,
      modalTitle:''
    };
  }

  printBestEmployeesOfYear = (bool) => {
    this.printAction(bool);
  }

  printBestEmployeesOfMonth = (month, bool) => {
    this.printActionMonth(month, bool);
  }

  printBestEmployeesOfQuarter = (quarter, bool) => {
    this.printActionQuarter(quarter, bool);
  }

  printAction = (bool) => {
    const { topEmployees } = this.props;
    let updatedYearEmployeeCSV = topEmployees && topEmployees.length > 0 &&
      topEmployees.map((best) => {
        let obj = {};
        obj['NAME'] = best['name'];
        obj['DEPARTMENT'] = best['department'];
        obj['TOTALRECOGNIZED'] = best['total_recognized'];
        return obj;
      })
    this.setState({ printYearEmployeeCSV: bool, updatedYearEmployeeCSV: updatedYearEmployeeCSV })
  };

  printActionMonth = (month, bool) => {
    const { topMonthEmployees } = this.props;
    let updatedMonthEmployeeCSV = topMonthEmployees[month] && topMonthEmployees[month].length > 0 &&
      topMonthEmployees[month].map((bestv) => {
        let obj = {};
        obj['NAME'] = bestv['name'];
        obj['TOTALMONTHLYRECOGNIZED'] = bestv['total_monthly_recognized'];
        return obj;
      })
    const monthTitle = `${month} Employee of the Month`
    this.setState({ printMonthEmployeeCSV: bool, fileTitleMonth: monthTitle, updatedMonthEmployeeCSV: updatedMonthEmployeeCSV })
  };

  printActionQuarter = (quarter, bool, quarterHeadings) => {
    const { topQuarterEmployees } = this.props;
    let updatedQuarterEmployeeCSV = topQuarterEmployees[quarter] && topQuarterEmployees[quarter].length > 0 &&
      topQuarterEmployees[quarter].map((bestQuater) => {
        let obj = {};
        obj['NAME'] = bestQuater['name'];
        obj['TOTALQUARTERLYRECOGNIZED'] = bestQuater['total_quarterly_recognized'];
        return obj;
      })
    const quarterTitle = `Employee of the ${quarterHeadings}`
    this.setState({ printQuarterEmployeeCSV: bool, fileTitleQuarter: quarterTitle, updatedQuarterEmployeeCSV: updatedQuarterEmployeeCSV })
  };

  onEnterMouse = (uid, id, name) => {
    this.setState({
      userId: uid,
      initiativeId: id,
      initiativeName: name
    })
  };

  onLeaveMouse = () => {
    this.setState({
      userId: null,
      initiativeId: null
    })
  };

  fallBack = (e) => {
    e.target.src = '/public/images/default.png'
  }

  handleSeeAll = (data, month) => {
    this.setState({ SeeAll: true, SeeAllMembersData: data, modalTitle: this.state.showTeams ? month + " Birthday's Celebration" : month + " Work Anniversaries" });
  };

  hideSeeAllModal = () => {
    this.setState({ SeeAll: false });
  };

  SeeAllModal = () => {
    const { SeeAllMembersData, SeeAll, showTeams, modalTitle } = this.state;
    return (
      <SeeAllStyledModal
        show={SeeAll}
        onHide={this.hideSeeAllModal}
        dialogClassName="modal-90w"
      >
        <Modal.Header style={{background:'#005c87'}}>
          <CustomTitle>
            <div>
              <span style={{textTransform:'capitalize'}}>{modalTitle}</span>
              <span className="closeImage" onClick={this.hideSeeAllModal}>
                <img src="/public/images/new_close_icon.png" />
              </span>
            </div>
          </CustomTitle>
        </Modal.Header>
        <Modal.Body>
          <TeamCardNew>
            <div className="teamDetails">
              {SeeAllMembersData.length > 0
                ? SeeAllMembersData.map((member, index) => (
                  <MemberDataV3
                    key={index}
                    captain={member.is_captain === 1}
                    border={index === 0}
                  >
                    <div style={{display:'flex',justifyContent:'space-between', width:'100%', alignItems:'center'}}>
                      <div style={{display:'flex', alignItems:'center'}}>
                        <div className="imageDiv">
                          {" "}
                          {member.is_captain === 1 && (
                            <CaptainLogo src="/public/images/captain-band.png" />
                          )}
                          <LazyImage src={ImageUrl + "/" + member.profile_image} alt={member.name}/>
                        </div>
                        <div className="textBox">
                          <h1 style={{color:'#005c87', fontFamily:'Rubik-medium', fontSize:'14px', lineHeight:'20px'}}>
                            {member.name}{" "}
                            {member.is_captain === 1 && (
                              <span className="max"> &nbsp;(Captain)</span>
                            )}{" "}
                          </h1>
                          {(member.is_champion && showTeams)  ? <WellnessChampion/> : !showTeams ? (<div style={{width:'100%', color:"#669db7",fontSize:'12px', lineHeight:'14.22px'}}>{moment(new Date()).diff(member.work_anniversary, 'years') + " year Anniversary"}</div>) : null}
                        </div>
                      </div>
                      <div>
                        <span style={{color:'#005c87', fontSize:'14px', lineHeight:'16.2px'}}>{showTeams ? moment(member.birthday).format('DD MMMM') : moment(member.work_anniversary).format('DD MMMM')}</span>
                      </div>
                    </div>
                  </MemberDataV3>
                ))
                : null}
            </div>
          </TeamCardNew>
        </Modal.Body>
      </SeeAllStyledModal>
    );
  };

  showUser = (employeesOfTheMonthDetails, employeesOfTheMonthlylyDetails, employeesOfTheMonthByQuarterlyDetails) => {
    const {  showTeams } = this.state;

    const Employees = [];

    for (const key in employeesOfTheMonthlylyDetails) {
      if (employeesOfTheMonthlylyDetails[key] !== null) {
        const newObj = { ...employeesOfTheMonthlylyDetails[key], month: `${key}` };
        Employees.push(newObj);
      }
    }

    const Quarters = [];

    for (const key in employeesOfTheMonthByQuarterlyDetails) {
      if (employeesOfTheMonthByQuarterlyDetails[key] !== null) {
        const newObj = { ...employeesOfTheMonthByQuarterlyDetails[key], quater: `${key}` }
        Quarters.push(newObj);
      }
    }

    return employeesOfTheMonthlylyDetails && Object.keys(employeesOfTheMonthlylyDetails).length ? Object.keys(employeesOfTheMonthlylyDetails).map((data, index) => (
      employeesOfTheMonthlylyDetails[data].length !== 0 &&
        <TopRecognitionContainerV2 key={data.uid || index} >
          <RecognitionReceivedContainerV2 key={index} >
            <RecognitionReceivedHeaderV2>
              <span>{data}</span>
              {/* <img style={{ width: '32px', height: '32px', cursor: 'pointer' }} src={'/public/images/download_icon-web.svg'} onClick={() => this.printActionMonth(data, true)} /> */}
            </RecognitionReceivedHeaderV2>
            <RecognisedUserV2 >
              {employeesOfTheMonthlylyDetails[data] && employeesOfTheMonthlylyDetails[data].users && employeesOfTheMonthlylyDetails[data].users.length > 0 && employeesOfTheMonthlylyDetails[data].users.map((user, index) => (
                index < 5 &&
                <UserProfileContainerV2 style={{ display: 'flex', alignItems: 'center' }} active={index === 2} key={index}>
                  <LazyImage src={ImageUrl + "/" + user.profile_image} />
                  <UserNameContainerV2 style={{width:'100%'}}>
                    <NameV2 fontFamily={'Rubik-Medium'} color={'#005c87'} textAlign={1}
                      active={true}>{user.name}</NameV2>
                    {(user.is_champion && showTeams)  ? <WellnessChampion/> : !showTeams ? (<div style={{width:'100%', color:"#669db7",fontSize:'12px', lineHeight:'14.22px'}}>{moment(new Date()).diff(user.work_anniversary, 'years') + " year Anniversary"}</div>) : null}
                  </UserNameContainerV2>
                  <RecognitionFrequencyV2 active={index === 2}>
                    <span color={'#005c87'}>{showTeams ? moment(user.birthday).format('MMM D') : moment(user.work_anniversary).format('MMM D')} </span>
                  </RecognitionFrequencyV2>
                </UserProfileContainerV2>
              ))}
              {employeesOfTheMonthlylyDetails[data] && employeesOfTheMonthlylyDetails[data].users && employeesOfTheMonthlylyDetails[data].users.length > 5 && (
                <div className="viewAllMembers" onClick={() => this.handleSeeAll(employeesOfTheMonthlylyDetails[data].users, data)}>
                            See All {">"}
                </div> )}
            </RecognisedUserV2>
          </RecognitionReceivedContainerV2>

        </TopRecognitionContainerV2>
    )): 
      <CardContainerr>
        <h3>{showTeams ? "No Birthdays data found": "No Anniversaries data found"}</h3>
      </CardContainerr>
     
  };

  showEmployees = (employeesOfTheMonthDetails, employeesOfTheMonthlylyDetails, employeesOfTheMonthByQuarterlyDetails) => (
    <div>
      {isEmpty(employeesOfTheMonthlylyDetails) ?
        <CardContainerr>
          <h3>No data found</h3>
        </CardContainerr> : null}
      <CardContainerr>
        {isEmpty(employeesOfTheMonthlylyDetails) ? '' : this.showUser(employeesOfTheMonthDetails, employeesOfTheMonthlylyDetails, employeesOfTheMonthByQuarterlyDetails)}
      </CardContainerr>
    </div>
  );

  role = "hammer"
  yearList = ["Month", "Quarter", "Year"]

  updateLocationDropdown = (item) => {
    if(this.state.showTeams){
      this.props.fetchBirthdayList(this.props.companyId, item.id, this.state.departmentDropDownValue.id)
    }else{
      this.props.fetchAnniversaryList(this.props.companyId, item.id, this.state.departmentDropDownValue.id)
    }

    this.setState({ locationDropDownValue: item });
  };
  updateDepartmentDropdown = (item) => {
    // this.props.fetchBirthdayList(this.props.companyId, item.id);
    if(this.state.showTeams){
      this.props.fetchBirthdayList(this.props.companyId, this.state.locationDropDownValue.id, item.id)
    }else{
      this.props.fetchAnniversaryList(this.props.companyId, this.state.locationDropDownValue.id, item.id)
    }
    this.setState({ departmentDropDownValue: item });
  };

  goToPersonalProfile = (employeeId) => {
    const { history } = this.props;
    history.push(`/company/people/${employeeId}/personal-profile`)
  }

  teamButtonStatusHover = () => {
    this.setState({
      teamButtonActive: true,
    });
  };

  teamButtonStatus = () => {
    this.setState({
      teamButtonActive: false,
    });
  };



  hideShowTeam = (status) => {
    // const { history } = this.props;
    if(status){
      this.props.fetchBirthdayList(this.props.companyId)
    }else{
      this.props.fetchAnniversaryList(this.props.companyId)
    }
    this.setState({ showTeams: status, locationDropDownValue:{location:"All Locations",id:null},departmentDropDownValue:{department:"All Departments",id:null}, });
    // if (!status) {
    //   history.push("/company/people");
    // }
  };

  showTeamButton = () => {
    const { teamButtonActive, showTeams } = this.state;
    const popover = () => (
      <Popover
        id="popover-basic"
        style={{
          width: "auto",
          padding: "9px 14px",
          background: "#FFFFFF",
          border: "1px solid #005c87",
          boxShadow: "0px 13px 28px rgba(0, 0, 0, 0.3)",
          borderRadius: "6px",
          marginRight:'20px'
        }}
      >
        <div
          style={{
            fontFamily: "Rubik",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "12px",
            lineHeight: "20px",
            color: "#005c87",
            textAlign: "center"
          }}
        >
          {"Download"}
        </div>
      </Popover>
    );
    return (
      <BuddyTabContainer style={{background:'none', justifyContent:'space-between'}}>
        <div style={{display:'flex'}}>
          {<BuddyTab onClick={() => this.hideShowTeam(true)} 
            // style={{background: showTeams ? '#85c0ea': 'none',color:showTeams ? 'white': '#6399af', border:showTeams ? '' : '1px solid #6399af', margin:'0px', borderRadius:'3px', fontFamily:'Rubik'}} 
            active={showTeams ? true : false}> {"Birthdays"}
          </BuddyTab>}
          <BuddyTab
            active={showTeams ? false : true}
            onClick={() => this.hideShowTeam(false)}
            style={{width:"171px"}}
            // style={{background: !showTeams ? '#85c0ea': 'none',color:!showTeams ? 'white': '#6399af', border:!showTeams ? '' : '1px solid #6399af', width:'171px', margin:'0px 15px',borderRadius:'3px', fontFamily:'Rubik'}}
          >
            {"Work Anniversaries"}
          </BuddyTab>
        </div>

        <div style={{display:'flex'}}>
          <div>
            {/* <NewLabelV2 role={'role'}></NewLabelV2> */}
            {
              <YearDropDownV2 inputpadding="9px 0px"
                style={{border:'1px solid #669db7', background:'white'}}
                type="button"
                label="year"
                title={<div style={{display:'flex', textAlign: 'left'}}><div className="title" style={{ fontSize: '14px', lineHeight: '20px', fontFamily: 'Rubik',fontWeight:'400', color: this.state.locationDropDownValue.id ? '#005c87':'#669db7', width:'150px' }}>{this.state.locationDropDownValue.location.split(',').join("")}</div><div style={{marginLeft:'10px',display:'flex', alignItems:'center'}}><svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 7c0 .25-.096.502-.288.693l-6.034 6.02a.985.985 0 0 1-1.39 0 .979.979 0 0 1 0-1.387L5.628 7 .287 1.674a.979.979 0 0 1 0-1.387.984.984 0 0 1 1.39 0l6.034 6.02A.976.976 0 0 1 8 7Z" fill="#005c87"/></svg></div></div>}
                id="year"
                role={this.role}
                value={this.state.locationDropDownValue.location}
                onSelect={(index,/*listItem*/) => this.updateLocationDropdown(this.props.locationDetails[index])}
              >
                {
                  this.props.locationDetails && this.props.locationDetails.length && this.props.locationDetails.map((list, index) => (
                    <DropdownItem style={{ fontSize: '14px' }} eventKey={index} key={index} onSelect={() => this.updateLocationDropdown(list)}>{list.location.split(',').join("")}</DropdownItem>
                  ))
                }
              </YearDropDownV2>
            }
          </div>
          <div style={{ marginLeft:'15px'}}>
            {/* <NewLabelV2 role={'role'}></NewLabelV2> */}
            {
              <YearDropDownV2 inputpadding="9px 0px"
                style={{border:'1px solid #669db7', background:'white'}}
                type="button"
                label="year"
                title={<div style={{display:'flex', alignItems:'center', textAlign: 'left'}}><div className="title" style={{ fontSize: '14px', lineHeight: '20px', fontFamily: 'Rubik',fontWeight:'400', color: this.state.departmentDropDownValue.id ? '#0D4270':'#669db7', width:'150px' }}>{this.state.departmentDropDownValue.department}</div><div style={{marginLeft:'10px',display:'flex', alignItems:'center'}}><svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 7c0 .25-.096.502-.288.693l-6.034 6.02a.985.985 0 0 1-1.39 0 .979.979 0 0 1 0-1.387L5.628 7 .287 1.674a.979.979 0 0 1 0-1.387.984.984 0 0 1 1.39 0l6.034 6.02A.976.976 0 0 1 8 7Z" fill="#005c87"/></svg></div></div>}
                id="year"
                role={this.role}
                value={this.state.departmentDropDownValue.department}
                onSelect={(index,/*listItem*/) => this.updateDepartmentDropdown(this.props.departmentDetails[index])}
              >
                {
                  this.props.departmentDetails && this.props.departmentDetails.length > 0 && this.props.departmentDetails.map((list, index) => (
                    <DropdownItem style={{ fontSize: '14px' }} eventKey={index} key={index} onSelect={() => this.updateDepartmentDropdown(list)}>{list.department}</DropdownItem>
                  ))
                }
              </YearDropDownV2>
            }
          </div>

          <ToolTip
            active={teamButtonActive}
            onMouseEnter={() => this.teamButtonStatusHover()}
            onMouseLeave={() => this.teamButtonStatus()}
            style={{margin:'0px', width:'auto'}}
          >
            <OverlayTrigger placement="bottom" overlay={popover()}>
              <div className='="toolTip' style={{cursor:'pointer', margin:'0px 0px 0px 15px',background:!teamButtonActive ? `#eaeee6` : '#005c87', }} onClick={() => this.props.exportToExcel(this.state.showTeams)}>
                {/* <svg width="22" height="24" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.778 15.72H14.19v-2.13h3.587c1.066 0 1.894-.544 1.894-1.013V3.142c0-.469-.828-1.014-1.894-1.014H4.222c-1.067 0-1.894.545-1.894 1.014v9.436c0 .468.827 1.013 1.894 1.013h3.586v2.128H4.222C1.967 15.72.2 14.34.2 12.578V3.142C.2 1.38 1.967 0 4.222 0h13.556C20.033 0 21.8 1.38 21.8 3.142v9.436c0 1.761-1.767 3.142-4.022 3.142Z" fill={ !teamButtonActive ? "#0D4270" : "white"}/><path d="M7.851 18.696c.273 0 .545.104.752.312l1.333 1.332V9.515a1.064 1.064 0 1 1 2.127 0v10.897l1.404-1.404a1.061 1.061 0 0 1 1.505 0 1.062 1.062 0 0 1 0 1.504l-3.177 3.177a1.06 1.06 0 0 1-.752.311h-.016a1.06 1.06 0 0 1-.752-.311L7.1 20.512a1.063 1.063 0 0 1 .752-1.816Z" fill={ !teamButtonActive ? "#0D4270" : "white"}/></svg> */}
                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="40" height="40" rx="3" fill={ !teamButtonActive ? "#005c87" : "white"} fillOpacity="0.05"/>
                  <path d="M27.2949 12.0432C27.2949 11.4779 26.8221 11 26.2517 11H13.7434C13.1781 11 12.7002 11.4728 12.7002 12.0432V13.9806H27.2949V12.0432Z" fill={ !teamButtonActive ? "#005c87" : "white"} fillOpacity={!teamButtonActive ? "0.6" : "1"}/>
                  <path d="M10.5417 24.4645H12.4431V20.5846C12.4431 20.3019 12.6898 20.045 12.9776 20.045H27.0224C27.3051 20.045 27.5569 20.2968 27.5569 20.5846V24.4645H29.4583C30.3062 24.4645 31 23.7656 31 22.9125V16.5556C31 15.7077 30.3062 15.0088 29.4583 15.0088H27.7984H12.2016H10.5417C9.69376 15.0088 9 15.7026 9 16.5556V22.9177C9 23.7656 9.69376 24.4645 10.5417 24.4645Z" fill={ !teamButtonActive ? "#005c87" : "white"} fillOpacity={!teamButtonActive ? "0.6" : "1"}/>
                  <path d="M13.4707 29.1919H26.5237V24.9676V21.0723H13.4707V24.9676V29.1919ZM16.2406 23.2306H19.6426C19.9253 23.2306 20.1565 23.4619 20.1565 23.7445C20.1565 24.0272 19.9253 24.2584 19.6426 24.2584H16.2406C15.958 24.2584 15.7267 24.0272 15.7267 23.7445C15.7267 23.4619 15.958 23.2306 16.2406 23.2306ZM16.2406 26.0057H23.7538C24.0365 26.0057 24.2677 26.2369 24.2677 26.5196C24.2677 26.8022 24.0365 27.0335 23.7538 27.0335H16.2406C15.958 27.0335 15.7267 26.8022 15.7267 26.5196C15.7267 26.2369 15.958 26.0057 16.2406 26.0057Z" fill={ !teamButtonActive ? "#005c87" : "white"} fillOpacity={!teamButtonActive ? "0.6" : "1"}/>
                </svg>
              </div>
            </OverlayTrigger>
          </ToolTip>
        </div>
      </BuddyTabContainer>
    );
  };

  

  render() {
    const { employeesOfTheMonthDetails, employeesOfTheMonthByQuarterlyDetails, birthdayList, anniversaryList } = this.props;
    const { updatedYearEmployeeCSV, printYearEmployeeCSV, updatedMonthEmployeeCSV, printMonthEmployeeCSV, updatedQuarterEmployeeCSV, printQuarterEmployeeCSV, showTeams } = this.state;

    const fileTitle = "2022 Employee of the year"
    const csvHeaders =
      [
        { label: "NAME", key: "NAME" },
        { label: "DEPARTMENT", key: "DEPARTMENT" },
        { label: "TOTAL YEARLY RECOGNIZED", key: "TOTALRECOGNIZED" }
      ]

    const csvHeadersMonth =
      [
        { label: "NAME", key: "NAME" },
        { label: "TOTAL MONTHLY RECOGNIZED", key: "TOTALMONTHLYRECOGNIZED" },
      ]

    const csvHeadersQuarter =
      [
        { label: "NAME", key: "NAME" },
        { label: "TOTAL QUARTERLY RECOGNIZED", key: "TOTALQUARTERLYRECOGNIZED" },
      ]
    return (
      <div>
        <div style={{ marginTop: '25px', marginBottom: '10px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>

          {this.showTeamButton()}

          {/* <div>
            {this.state.dropDownValue === 'Year' &&
              <button style={{ cursor: 'pointer', border: 'none', padding: '0px' }} onClick={() => this.printBestEmployeesOfYear(true)}>
                <img className='download_img' style={{ width: '32px', height: '32px', cursor: 'pointer' }} src={'/public/images/AdminChallenges/printer.png'}  />
              </button>
            }
          </div> */}

          

        </div>
        {showTeams ?  this.showEmployees(employeesOfTheMonthDetails, birthdayList, employeesOfTheMonthByQuarterlyDetails) : this.showEmployees(employeesOfTheMonthDetails, anniversaryList, employeesOfTheMonthByQuarterlyDetails)}
        {this.state.SeeAll && this.SeeAllModal()}
        {printYearEmployeeCSV && <CSVList Title={fileTitle} removeCSVData={this.printAction} headers={csvHeaders} data={updatedYearEmployeeCSV} />}
        {printMonthEmployeeCSV && <CSVList Title={this.state.fileTitleMonth} removeCSVData={this.printActionMonth} headers={csvHeadersMonth} data={updatedMonthEmployeeCSV} />}
        {printQuarterEmployeeCSV && <CSVList Title={this.state.fileTitleQuarter} removeCSVData={this.printActionQuarter} headers={csvHeadersQuarter} data={updatedQuarterEmployeeCSV} />}
      </div>
    )
  }
}
Anniversaries.propTypes = {
  employeesOfTheMonthDetails: PropTypes.object,
  // recognitionDetails: PropTypes.array,
  // employeeList: PropTypes.array.isRequired,
  selectedEmployee: PropTypes.any,
  birthdayList: PropTypes.object,
  anniversaryList:PropTypes.object,
  employeesOfTheMonthByQuarterlyDetails: PropTypes.object,
  history: PropTypes.object,
  topEmployees: PropTypes.array,
  topMonthEmployees: PropTypes.array,
  topQuarterEmployees: PropTypes.array,
  departmentDetails: PropTypes.array,
  locationDetails: PropTypes.array,
  fetchBirthdayList:PropTypes.func,
  fetchAnniversaryList:PropTypes.func,
  companyId:PropTypes.string,
  exportToExcel:PropTypes.func,
}

const mapStateToProps = (state) => ({
  departmentDetails: state.companyDashboard.companyPeopleDepartment,
  locationDetails: state.companyDashboard.companyPeopleLocation,
})
export default connect(mapStateToProps, null)((Anniversaries));