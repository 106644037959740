/* eslint-disable no-undef  */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { FriendsContainer, Button, StyledBody, StyledTable } from './styles';
import { NoComments } from '../SocialFeeds/styles';
import Image from '../Image';

import { getPageNumber, searchAmigos, sendFriendRequest } from '../../redux/actions';
import Waiting from '../Waiting';

const AmigoList = ({ list, fetchPageNumber, pageNumber, searchUser, sendFriendRequest, companyId, totalAmigoCount, isLoading }) => {
  const onScroll = (e) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight ? true : false;
    if(bottom) {
      if(totalAmigoCount > list.length) {
        fetchPageNumber();
        searchUser(pageNumber, companyId);
      }
    }
  };

  return(
    <FriendsContainer>
      { isLoading && <Waiting />}
      <StyledTable>
        <thead>
          <tr>
            <th className="col-xs-4">User</th>
            <th className="col-xs-4">Areas of Interest</th>
            <th className="col-xs-4" />
          </tr>
        </thead>
        {
          list && list.length > 0 ?
            <StyledBody onScroll={(e) => onScroll(e)}>
              {
                list.map((amigo, index) => (
                  <tr key={index}>
                    <td className="col-xs-4">
                      <div className="flex">
                        <Image image={amigo.profile_image} alt='user'/>
                        <div className='userName'>{amigo.fullname}</div>
                      </div>
                    </td>
                    <td className="col-xs-4">
                      <span>{amigo.interest_name}</span>
                    </td>
                    <td className="col-xs-4 align">
                      {
                        amigo.status === 'Pending' ? <Button >Pending</Button>:
                          amigo.status !== 'Accepted' ? <Button onClick={() => sendFriendRequest(amigo.uid)}>+Buddy</Button>:
                            <Button disabled={true}>Accepted</Button>
                      }
                    </td>
                  </tr>
                ))
              }
            </StyledBody> :
            <StyledBody>
              <tr>
                <td>
                  <NoComments>No buddies found</NoComments>
                </td>
              </tr>
            </StyledBody>
        }
      </StyledTable>
    </FriendsContainer>
  );
};

AmigoList.propTypes = {
  list: PropTypes.array.isRequired,
  pageNumber: PropTypes.number.isRequired,
  fetchPageNumber: PropTypes.func.isRequired,
  searchUser: PropTypes.func.isRequired,
  sendFriendRequest: PropTypes.func.isRequired,
  companyId: PropTypes.bool,
  totalAmigoCount: PropTypes.number,
  isLoading: PropTypes.bool
};

const mapStateToProps = (state) => ({
  pageNumber: state.profileData.pageNumber
});

const mapDispatchToProps = (dispatch) => ({
  fetchPageNumber: () => dispatch(getPageNumber()),
  searchUser: (pageNumber, companyId) => dispatch(searchAmigos(pageNumber, companyId)),
  sendFriendRequest: (userId) => dispatch(sendFriendRequest(userId))
});

export default connect(mapStateToProps, mapDispatchToProps)(AmigoList);
