import styled from 'styled-components';

import { PhotosContainer } from '../MyPhotos/styles';

const BoldFont = 'Rubik-Medium';

const FriendsContainer = styled(PhotosContainer)`
  width: 100%;
  margin-top: 20px;
  padding: 20px 10px;

  @media (max-width: 500px) {
    margin-bottom: 0;
    padding: 15px 0;
  }
`;

const Button = styled.button`
  background: #40c4fe;
  font-size: 14px;
  padding: 8px 12px;
  border-radius: 5px;
  margin: 10px 0px;
  color: #fff;
  text-decoration: none;
  border: none;
  font-family: ${BoldFont};
  cursor: ${({disabled}) => disabled && 'not-allowed !important'};

  &:focus, &:active, &:hover {
    outline: none;
  }
`;

const StyledTable = styled.table`
  width: 100%;

  thead,tbody,tr {
    display: block;
    width: 100%;
  }

  td, th {
    display: inline-block !important;
  }

  th {
    text-align: left;
    padding-bottom: 15px !important;
    font-family: ${BoldFont};
    display: block;
  }

  tr {
    border-bottom: 1px solid lightgray;
  }
`;

const StyledBody = styled.tbody`
  overflow-y: auto;
  overflow-x: hidden;
  height: auto;
  max-height: 500px;

  tr {
    padding: 10px 0;
    .align {
      text-align: center;
    }
  }

  td {
    vertical-align: middle !important;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;

    .flex {
      display: flex;
      align-items: center;
      @media (max-width: 500px) {
        display: block;
      }
      > img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        @media (max-width: 500px) {
          margin-right: 0px;
          margin-bottom: 5px;
          margin-left: 0px;
        }
      }
    }
    .userName {
      width: calc(100% - 70px);
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-left: 10px;
      text-transform: capitalize;
      white-space: nowrap;
      @media (max-width: 500px) {
        display: block;
        margin-left: 0;
        width: 100%;
        white-space: normal;
        line-height: initial;
      }
    }
  }

    &::first-letter {
      text-transform: capitalize;
    }
  }
`;

export { FriendsContainer, Button, StyledBody, StyledTable };
